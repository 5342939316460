<template>
  <div class="business">
    <div class="business_bg scaleBanner">
      <div class="business_bg_name moveTopTitle">药材合集</div>
    </div>
    <!-- <div class="business_head">
      <div class="business_head_title">药材合集 > {{ tabList[tabIndex] }}</div>
    </div> -->
    <div class="center_title">
      <img
        src="../../../static/newImg2/home.png"
        width="16px"
        @click="toPage('/')"
      />
      <span class="center_home" @click="toPage('/Specific_m')"> > 药材合集</span>
      <span class="center_home"> > {{ medicineDetail.medicineName }}</span>
    </div>
    <div class="business_list">
      <div class="business_list_right" v-if="medicineDetail.medicineName">
        <div class="business_list_right_head moveTopNormal">
          <img
            :src="
              require('../../../static/image/' +
                medicineDetail.medicineName +
                '.jpg')
            "
            width="710px"
          />
          <div class="business_list_right_head_in">
            <b class="business_list_right_head_in_name"
              >道地药材-{{ medicineDetail.medicineName }}</b
            >
            <div class="margin-20">
              <b>产品介绍</b>
            </div>
            <div class="margin-20">
              <span>中药名称：</span
              ><span>{{ medicineDetail.medicineName }}</span>
            </div>
            <div class="margin-20">
              <span>别 名：</span
              ><span>{{ medicineDetail.medicineOtherName }}</span>
            </div>
            <div class="margin-20">
              <span>中药学名（拉丁名）：</span
              ><span>{{ medicineDetail.medicineName }}</span>
            </div>
            <div class="margin-20">
              <span>产区分布：</span
              ><span>{{ medicineDetail.medicineOriginPlace }}</span>
            </div>
            <div class="margin-20">
              <span>科：</span
              ><span>{{ medicineDetail.medicineFamilies }}</span>
            </div>
            <div class="margin-20">
              <span>功 效：</span
              ><span>{{ medicineDetail.medicineEffect }}</span>
            </div>
          </div>
        </div>
        <div class="moveTopNormal" v-show="this.id == 520">
          <div class="title">
            <div class="kuai_green"></div>
            <div class="title_dom">相关项目基地</div>
          </div>
          <div class="Base">
            <div
              :style="`background:url(${business_bg1})`"
              class="CorrelationBase"
              @click="onClisk"
              @mousemove="mousemove(index)"
              v-for="(item, index) in 1"
              :key="index"
            >
              <div class="bgdom">
                <div
                  v-if="mouseType || mouseindex == index"
                  class="CorrelationBase_left"
                >
                  <img src="../../../static/v1.0/L.png" alt="" />
                </div>
                <div class="CorrelationBase_font">
                  中峪道地药材平顶山仿野生白术种植基地
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="title" style="margin: 100px 0 100px 0">
            <div class="kuai_green"></div>
            <div class="title_dom">产品描述</div>
          </div>
        <!-- <div class="business_list_right_line"></div> -->
        <div
          class="business_list_right_content margin-20 moveTopNormal"
          v-html="medicineDetail.medicineDetail"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getAccessToken, getSignature } from "@/api/index";
export default {
  name: "business",
  data() {
    return {
      tabList: [
        "关药",
        "北药",
        "怀药",
        "维药",
        "浙药",
        "海药",
        "南药",
        "蒙药",
        " 川药",
        "云药",
        "贵药",
        "广药",
        "淮药",
        "藏药",
        "秦药",
      ],
      mouseType: false,
      mouseindex: 0,
      medicineDetail: {},
      tabIndex: 0,
      query: {
        page: 0,
        size: 8,
        total: 0,
      },
      id: "",
      medicineDetailImg: "",
      business_bg: require("../../../static/newImg2/business_bg.png"),
      business_bg1: "https://www.zoneyu.cc/img/img95.13aa8cee.jpg",
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.tabIndex = this.$route.query.tabIndex;
    window.addEventListener("popstate", this.handlePopState);
    this.getInfo();
    window.scroll(0, 0);
    this.getWXInfo();
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.handlePopState);
  },
  methods: {
    mousemove(e) {
      this.mouseType = false;
      this.mouseindex = e;
    },
    handlePopState(event) {
      console.log(event);
    },
    getWXInfo() {
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp;
        const nonceStr = res.data.noncestr;
        const signature = res.data.signature;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: "wxad8dd02ae97d307f", // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {
          //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: "中峪道地药材", // 分享标题
            desc: "药材合集", // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: "	https://www.zoneyu.cc/img/%E8%BF%9C%E5%BF%97.3fda36cd.jpg", // 分享图标
          };
          wx.updateAppMessageShareData(obj);
          wx.updateTimelineShareData(obj);
        });
      });
    },
    getInfo() {
      axios
        .get(`https://t2.zyjkjt.net/cms/api/app/medicine/detail/` + this.id)
        .then(async (res) => {
          this.medicineDetail = res.data.data;
        });
    },
    onClisk() {
      console.log("11");
      this.$router.push({
        path: "/projectDetail?id=101",
        // query: { tabIndex: this.tabIndex },
      });
    },
    setTabIndex(index) {
      this.tabIndex = index;
      this.$router.push({
        path: "/business",
        query: { tabIndex: this.tabIndex },
      });
    },
    toPage(path) {
      this.$router.push({ path });
    },
  },
};
</script>

<style scoped lang="scss">
.margin-20 {
  margin-top: 24px;
}

.business {
  .business_bg {
    height: 560px;
    position: relative;
    overflow: hidden;
    width: 100%;
    background-image: url("../../../static/newImg2/business_bg.png");
    background-repeat: no-repeat;
    background-size: cover;

    .business_bg_name {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
      font-size: 56px;
      color: #ffffff;
      text-align: center;
      letter-spacing: 1px;
      font-family: 黑体;
    }
  }
}

.business_head {
  width: 1280px;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 400;
  color: #858585;
  line-height: 20px;

  .business_head_title {
    margin-top: 40px;
  }
}

.business_list {
  display: flex;
  //   width: 1280px;
  margin: 0 160px;
  margin-top: 30px;

  .business_list_left {
    width: 240px;
    margin-right: 40px;

    .business_list_left_head {
      width: 240px;
      height: 64px;
      background: #18511c;
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      line-height: 64px;
      letter-spacing: 15px;
      text-align: center;
    }

    .business_list_left_tabList_tab {
      width: 240px;
      height: 64px;
      border-bottom: 1px solid #f5f5f7;
      border-left: 1px solid #f5f5f7;
      border-right: 1px solid #f5f5f7;
      line-height: 64px;
      text-align: center;
      cursor: pointer;
      position: relative;

      .isActive {
        background: #f9f9f7;
      }
    }

    .isActive::after {
      content: "";
      position: absolute;
      top: 25px;
      left: 170px;
      width: 0;
      height: 0;
      border: 7px solid transparent;
      /*以下四个样式对应四种三角形，任选其一即可实现*/
      border-left-color: #18511c;
    }
  }

  .business_list_right {
    width: 1600px;
    margin: 0 auto;
    margin-top: 87px;

    .business_list_right_head {
      display: flex;

      .business_list_right_head_in {
        margin-left: 50px;
        padding: 30px 0;
        margin-left: 71px;
        .business_list_right_head_in_name {
          font-size: 55px;
          margin-bottom: 57px;
          font-family: 楷体;
          //   color: #d3000f;
        }
      }
    }

    .business_list_right_line {
      margin-top: 40px;
      height: 1px;
      width: 100%;
      background-color: #ddd;
    }

    .business_list_right_content {
      letter-spacing: 1px;
      margin-bottom: 40px;
      border-radius: 25px;
      padding: 51px;
      border: 1px solid #e7e7e7;
      b {
        font-size: 22px;
      }
    }
  }
}
.title {
  height: 60px;
  display: flex;
  margin: 153px 0 60px 0;
  .kuai_green {
    width: 6px;
    margin: 16px;
    margin-left: 0;
    height: 28px;
    background: #157b2f;
  }
  .title_dom {
    font-weight: bold;
    font-size: 40px;
    color: #000000;
    line-height: 60px;
    letter-spacing: 1px;
    text-align: left;
  }
}
.Base {
  display: flex;
  .CorrelationBase:hover {
    .bgdom {
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.1),
        #0b640d
      ) !important;
    }
  }
  .CorrelationBase {
    cursor: pointer;
    position: relative;
    color: #fff;
    width: 508px;
    height: 268px;
    background-size: cover !important;
    .bgdom {
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 100px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), #030302);
    }
  }
  .CorrelationBase_left {
    bottom: 60px;
    left: 16px;
    font-family: 楷体;
    position: absolute;
  }
  .CorrelationBase_font {
    bottom: 31px;
    left: 16px;
    font-family: 楷体;
    position: absolute;
  }
  .Correlation_bg {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), #030302);
  }
  .CorrelationBase:nth-child(2n) {
    margin: 0 38px;
  }
}

.business_pagination {
  margin: 0 auto;
  margin-top: 16px;
  text-align: center;
  margin-bottom: 64px;
  padding-left: 280px;
  width: 1000px;
}

::v-deep .el-pager li.active {
  width: 28px;
  height: 28px;
  background: #354a32;
  border-radius: 2px;
  color: #ffffff;
  line-height: 28px;
}

::v-deep .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  color: #565759;
  line-height: 28px;
  padding: 0;
  margin: 0 4px;
  font-weight: 400;
}

::v-deep .el-pager li.active + li {
  border: 1px solid #e5e5e5;
}
.center_title {
  width: 1600px;
  margin: 0 auto;
  margin-top: 54px;
  z-index: 9;
  position: relative;

  & > img {
    cursor: pointer;
  }

  .center_home {
    font-weight: 400;
    font-size: 16px;
    color: #1d1b19;
    vertical-align: top;
    cursor: pointer;
  }
}
</style>
